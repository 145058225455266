
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required_if, confirmed } from 'vee-validate/dist/rules'
import { Component, Vue } from 'vue-property-decorator'
import { IUserProfileUpdate } from '@/interfaces/users'
import userModule from '@/store/user'

extend('required_if', {
  ...required_if,
  message: 'This field is required'
})
extend('confirmed', { ...confirmed, message: 'Passwords do not match' })

@Component({
  components: { ValidationObserver, ValidationProvider }
})
export default class UserEditView extends Vue {
  public fullName = ''
  public email = ''
  public isActive = true
  public isSuperuser = false
  public password1 = ''
  public password2 = ''

  public async mounted() {
    this.reset()
  }

  public reset() {
    this.password1 = ''
    this.password2 = ''

    if (this.user) {
      this.fullName = this.user.full_name
      this.email = this.user.email
      this.isActive = this.user.is_active
      this.isSuperuser = this.user.is_superuser
    }
  }
  public cancel() {
    this.$router.back()
  }
  public async deleteUser() {
    if (confirm('Are you sure?')) {
      userModule.actionDeleteUser({ id: this.user!.id })
      this.$router.push({ name: 'admin-users-all' })
    }
  }
  public async submit() {
    const updatedProfile: IUserProfileUpdate = {}
    if (this.fullName) {
      updatedProfile.full_name = this.fullName
    }
    if (this.email) {
      updatedProfile.email = this.email
    }
    updatedProfile.is_active = this.isActive
    updatedProfile.is_superuser = this.isSuperuser
    if (this.password1 !== '') {
      updatedProfile.password = this.password1
    }
    userModule.actionUpdateUser({
      id: this.user!.id,
      user: updatedProfile
    })
    this.$router.push({ name: 'admin-users-all' })
  }
  get user() {
    return userModule.user(+this.$router.currentRoute.params.id)
  }
}
